export function useDateFormatter () {
	const dayDateFormat = new Intl.DateTimeFormat(
		globalThis.incrowd.intl.lang,
		globalThis.incrowd.intl.dayOptions,
	).format

	const timeDateFormat = new Intl.DateTimeFormat(
		globalThis.incrowd.intl.lang,
		globalThis.incrowd.intl.timeOptions,
	).format

	return {
		dayDateFormat,
		timeDateFormat,
	}
}

export const getSectionWeekday = (date: string) => {
	return new Intl.DateTimeFormat('default', { weekday: 'short' }).format(new Date(date))
}

export const getSectionDate = (date: string) => {
	return new Intl.DateTimeFormat('default', { day: 'numeric', month: 'short' }).format(new Date(date))
}

export const padDateUnit = (unit: string) => unit.padStart(2, '0')
